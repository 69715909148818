import React, {Component, useState} from 'react';

import {YMaps, Map, Placemark} from 'react-yandex-maps';
import Delivery from "./Delivery";


class Registration extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isLoaded_cat: false,
            productsFull: [],
            productsBasket: null,
            productsCategories: [],
            productsActive: [],
            categories: [],
            shopCompany: [],
            shopCompanyActive: null,
            customization: [],
            priceot: null,
            pricedo: null,
            categoriesActive: false,
            Cookie: null,
            // Cookie: '808c0385509a7b5bb5ff69dbaf2eec0a',
            // Cookie: 'a7cb90c779e8cd66531ede638d0371d7',
            dateOrder: null,
            FullPrice: 0,
            quantityProduct: 0,
            phone: '',
            email: '',
            orderId: '',
            name: '',
            CheckName: false,
            CheckPhone: false,
            CheckPhonePost: false,
            feedbackname: 'Данное поле должно быть заполнено',
            feedbacknameClass: 'form-control is-invalid',
            feedbacksurname: '',

            feedbackphone: 'Данное поле должно быть заполнено',
            feedbackphoneClass: 'form-control is-invalid',
            surname: '',
            address: '',
            comment: '',
            deliveryActive: false,
            pickupActive: true,
            PaymentOnSite: true,
            Online_pay: false,
            BankTransfer: false,
            CashlessPayments: false,
            isLoadedOrder: false,
            orderSuccessfull: false,
            idShop: this.props.idShop,
            ActiveShop: null,
            edge: '',
            city: '',
            street: '',
            promo: '',
            zip: '',
            activePay: [],
            Pay: [],
            delivery: [],
            resultDelivery: [],
            seconds: 0,


        };
        window.addEventListener('message', this.handleCookie);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeActivePay = this.handleChangeActivePay.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeSurname = this.handleChangeSurname.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangeComment = this.handleChangeComment.bind(this);
        this.handleClickPostBOrder = this.handleClickPostBOrder.bind(this);
        this.handleChangeDateOrder = this.handleChangeDateOrder.bind(this);
        this.handaleClickShopActivti = this.handaleClickShopActivti.bind(this);
        this.handleChangeEdge = this.handleChangeEdge.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeZip = this.handleChangeZip.bind(this);
        this.handleChangeStreet = this.handleChangeStreet.bind(this);
        this.orderReady = this.orderReady.bind(this);
        this.onChangeDelivery = this.onChangeDelivery.bind(this);
        this.timer = this.timer.bind(this);
        this.CheckPhoneApi = this.CheckPhoneApi.bind(this);
        this.handleChangePromo = this.handleChangePromo.bind(this);


    }

    onChangeDelivery(array) {
        console.log(array)
        this.setState({resultDelivery: array})
    }

    handleChangeAddress(event) {
        this.setState({address: event.target.value});
    }

    handleChangeDateOrder(event) {
        this.setState({dateOrder: event.target.value});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleChangePhone(event) {
        this.setState({CheckPhone: true, phone: event.target.value, feedbackphoneClass: 'form-control is-valid'});
        if (event.target.value == '') {
            this.setState({
                feedbackphone: 'Укажите полный номер начиная с 8',
                feedbackphoneClass: 'form-control is-invalid',
                CheckPhone: false
            });
        }
        if (event.target.value.search(/\d/) == -1) {
            this.setState({
                feedbackphone: 'Данное поле должно содержать только цифры',
                feedbackphoneClass: 'form-control is-invalid',
                CheckPhone: false
            });
        }
        if (event.target.value.length < 11) {
            this.setState({
                feedbackphone: 'Укажите полный номер начиная с 8',
                feedbackphoneClass: 'form-control is-invalid',
                CheckPhone: false
            });
        }
    }

    handleChangeSurname(event) {
        this.setState({surname: event.target.value});
        if (event.target.value == '') {
            this.setState({feedbacksurname: 'Данное поле должно быть заполнено'});
        }
        if (event.target.value.search(/\d/) != -1) {
            this.setState({feedbacksurname: 'Данное поле должно содержать только буквы'});
        }
    }
    handleChangePromo(event) {
        this.setState({promo: event.target.value});

    }

    handleChangeName(event) {
        this.setState({
            CheckName: true,
            name: event.target.value,
            feedbackname: '',
            feedbacknameClass: 'form-control is-valid'
        });
        if (event.target.value == '') {
            this.setState({
                feedbackname: 'Данное поле должно быть заполнено'
                , feedbacknameClass: 'form-control is-invalid',
                CheckName: false
            });
        }
        if (event.target.value.search(/\d/) != -1) {
            this.setState({
                feedbackname: 'Данное поле должно содержать только буквы',
                feedbacknameClass: 'form-control is-invalid',
                CheckName: false
            });
        }
    }

    handleChangeComment(event) {
        this.setState({comment: event.target.value});
    }

    handleChangeEdge() {

    }

    handleChangeCity() {

    }

    handleChangeStreet() {

    }

    handleChangeZip() {

    }

    handleClickPostBOrder() {
        let urlAPI = 'https://api.cliclishop.ru/widget/orders/add';

        if (this.state.FullPrice === 0) {
            // this.setState({
            //     error: "Ваша корзина пуста",
            //
            // });
        } else {
            let error = false

            if (this.state.CheckPhone == '') {
                error = true;

            }
            if (this.state.CheckName == '') {
                error = true;

            }
            if (this.state.resultDelivery.activeDelivery == 'Cамовывоз' && this.state.resultDelivery.ActiveShop == null) {
                error = true;

            }
            if (this.state.resultDelivery.activeDelivery == 'Доставка' && this.state.resultDelivery.error != false) {
                error = true;

            }

            if (!error) {
                // let address = '';
                // let shop = '';
                // let pay = '';
                //
                // if (this.state.deliveryActive) {
                //     address = this.state.edge + ' ' + this.state.city + ' ' + this.state.street
                // } else {
                //     shop = this.state.ActiveShop.id
                // }
                //
                //
                // const data = {
                //     hashShop: this.state.idShop,
                //     cookie: this.state.Cookie,
                //     name: this.state.name,
                //     surname: this.state.surname,
                //     phone: this.state.phone,
                //     email: this.state.email,
                //     shop: shop,
                //     address: address,
                //     comment: this.state.comment,
                //     dateOrder: this.state.dateOrder,
                //     pay: this.state.activePay.id,
                //     zip: this.state.zip
                // };
                //
                // let getParam = "hashShop=" + this.state.idShop +
                //     '&cookie=' + this.state.Cookie +
                //     '&name=' + this.state.name +
                //     '&surname=' + this.state.surname +
                //     '&phone=' + this.state.phone +
                //     '&email=' + this.state.email +
                //     '&address=' + address +
                //     '&shop=' + shop +
                //     '&comment=' + this.state.comment +
                //     '&dateOrder=' + this.state.dateOrder +
                //     '&pay=' + this.state.activePay.id +
                //     '&zip=' + this.state.zip
                // console.log(getParam)
                return (
                    <div onClick={() => this.orderReady()} className="clicli_ordering_button">

                        <div
                            className="clicli_result_title text-center">Оформить заявку
                        </div>

                    </div>
                )
            } else {
                return (
                    <div style={{background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.16) 0%, #6d6d6d 100%)'}}
                         className="clicli_ordering_button">
                        <a style={{color: 'white'}}
                        >
                            <div
                                className="clicli_result_title text-center">Оформить заявку
                            </div>
                        </a>
                    </div>
                )
            }


        }

    }

    orderReady() {

        this.PostOrder()

    }

    handleCookie = (e) => {

        if (e.data.hasOwnProperty("Cookie")) {

            this.setState({
                Cookie: e.data.Cookie,
            });

        }
    }

    PostOrder() {


        let urlAPI = 'https://api.cliclishop.ru/widget/orders/add';
        const data = {
            hashShop: this.state.idShop,
            cookie: this.state.Cookie,
            comment: this.state.comment,
            name: this.state.name,
            promo: this.state.promo
            ,
            surname: this.state.surname
            ,
            phone: this.state.phone
            ,
            email: this.state.email
            // ,
            // address: this.state.resultDelivery.edge + ' ' + this.state.resultDelivery.city + ' ' + this.state.resultDelivery.street
            // ,
            // zip: this.state.resultDelivery.zip
            // ,
            // shop: this.state.resultDelivery.ActiveShop.id
            // ,
            // pay: this.state.activePay
            // ,
            // typePay: this.state.activePay.id
            // ,
            // typeDelivery: this.state.resultDelivery.activeDelivery

        };
        fetch(urlAPI, {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
        })
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState(
                        {
                            orderSuccessfull: true,
                            orderId: result.id,


                        });

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        error
                    });
                }
            )


    }

    timer(second) {

        console.log(second)
        if (second > 0) {

            var newInterval = setInterval(() => {
                if(this.state.seconds==1){
                    clearInterval(newInterval);
                }
                this.setState({seconds: this.state.seconds - 1})
            }, 1000);
        }
    }

    CheckPhoneApi() {
        console.log(this.state.seconds)
        if (this.state.seconds ==0) {
            console.log('090')
            let urlAPI = 'https://api.cliclishop.ru/widget/callPassword/checkPhone';
            const data = {phone: this.state.phone};
            this.setState(
                {

                    CheckPhonePost: true,
                    seconds: 20,

                });


            this.timer(20)


            // fetch(urlAPI, {
            //         method: 'POST', // или 'PUT'
            //         body: JSON.stringify(data)
            //     } // данные могут быть 'строкой' или {объектом}!
            // )
            //
            //     .then(res => res.json())
            //     .then(
            //         (result) => {
            //
            //             this.setState(
            //                 {
            //
            //                     CheckPhonePost: true,
            //                     seconds: 180,
            //
            //                 });
            //             this.timer()
            //         },
            //         // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            //         // чтобы не перехватывать исключения из ошибок в самих компонентах.
            //         (error) => {
            //             this.setState({
            //                 error
            //             });
            //         }
            //     )
        }


    }

    CheckCodeApi() {


        let urlAPI = 'https://api.cliclishop.ru/widget/callPassword/checkPhone';
        const data = {phone: this.state.phone};
        fetch(urlAPI, {
                method: 'POST', // или 'PUT'
                body: JSON.stringify(data)
            } // данные могут быть 'строкой' или {объектом}!
        )

            .then(res => res.json())
            .then(
                (result) => {

                    this.setState(
                        {});

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        error
                    });
                }
            )


    }

    UpdateBasket() {

        if (this.state.Cookie != null) {

            if (this.state.productsBasket === null) {
                let urlAPI = 'https://api.cliclishop.ru/widget/basketShow/' + this.state.Cookie;

                fetch(urlAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            this.setState(
                                {
                                    productsBasket: result.product,
                                    FullPrice: result.FullPrice,
                                    quantityProduct: result.quantityProduct,


                                });

                        },
                        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                        // чтобы не перехватывать исключения из ошибок в самих компонентах.
                        (error) => {
                            this.setState({
                                error
                            });
                        }
                    )

            }
        }


    }

    handaleClickShopActivti(id) {
        console.log(id)
        const temp1 = this.state.shopCompany.filter(item => item.id == id);
        console.log(temp1)

        this.setState({
            shopCompanyActive: id,
            ActiveShop: temp1[0]


        });
    }

    handaleChangeActiveDelivery(e) {
        if (e == "Delivery") {
            this.setState({
                deliveryActive: true,
                pickupActive: false,
            });
        } else if (e == "Pickup") {
            this.setState({
                pickupActive: true,
                deliveryActive: false,
            });
        }
    }

    handleChangeActivePay(item) {
        console.log(item)
        this.setState({
            activePay: item
        });
    }

    componentWillMount() {
        window.addEventListener('message', this.handleCookie);


    }

    componentDidMount() {
        let urlAPI = 'https://api.cliclishop.ru/widget/customization/' + this.state.idShop;

        fetch(urlAPI)
            .then(res => res.json())
            .then(
                (result) => {

                    console.log(result)
                    console.log(result.shop[0])
                    if (result.shop.length > 0) {
                        this.setState({
                            shopCompany: result.shop,
                            shopCompanyActive: result.shop[0].id,
                            ActiveShop: result.shop[0],
                        })
                    }
                    if (result.activePay.length > 0) {
                        this.setState({
                            Pay: result.activePay,
                            activePay: result.activePay[0].payment,
                        })
                    }
                    this.setState(
                        {
                            customization: result.settings.settings,
                            delivery: result.delivery,

                            isLoaded: true,
                        });

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )


    }


    deliveryForm() {
        return (<form>
                <div className="form-row">
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="validationServer01">Край/область/регион</label>
                        <input type="text"
                               className={this.state.edge ? 'form-control is-valid' : 'form-control is-invalid'}
                               id="validationServer01" value={this.state.edge}
                               onChange={e => this.setState({edge: e.target.value})} required/>
                        {this.state.edge ? '' : <div className="invalid-feedback">
                            Пожалуйста, укажите край/область/регион
                        </div>}

                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="validationServer02">Город</label>
                        <input type="text"
                               className={this.state.city ? 'form-control is-valid' : 'form-control is-invalid'}
                               id="validationServer02" value={this.state.city}
                               onChange={e => this.setState({city: e.target.value})} required/>
                        {this.state.city ? '' : <div className="invalid-feedback">
                            Пожалуйста, укажите город
                        </div>}

                    </div>
                </div>
                <div className="form-row">
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="validationServer03">Улица, дом, квартира</label>
                        <input type="text"
                               className={this.state.street ? 'form-control is-valid' : 'form-control is-invalid'}
                               id="validationServer03" value={this.state.street}
                               onChange={e => this.setState({street: e.target.value})} required/>
                        {this.state.street ? '' : <div className="invalid-feedback">
                            Пожалуйста, укажите улицу, дом, квартиру
                        </div>}

                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="validationServer05">Почтовый индекс</label>
                        <input type="text"
                               className={this.state.zip ? 'form-control is-valid' : 'form-control is-invalid'}
                               id="validationServer05" value={this.state.zip}
                               onChange={e => this.setState({zip: e.target.value})}/>
                        {this.state.street.length > 11 ? '' : <div className="invalid-feedback">
                            Пожалуйста укажите почтовый индекс
                        </div>}

                    </div>
                </div>
            </form>
        )
    }

    pay() {
        return (
            this.state.Pay.map(items => (<div onClick={() => this.handleChangeActivePay(items.payment)}
                                              className={this.state.activePay === items.payment ? 'clicli_order_choose_btn active' : 'clicli_order_choose_btn'}>{items.payment.name}</div>))
        )


    }

    textPay() {
        console.log(this.state)
        return (
            <div className="clicli_online_pay  active">
                <p>{this.state.activePay ? this.state.activePay.content : '-'}<br/></p>
            </div>
        )


    }

    delivery() {

        const {
            idShop,
            error,
            isLoaded,
            productsBasket,
            feedbacksurname,
            feedbackname,
            deliveryActive,
            pickupActive,
            PaymentOnSite,
            Online_pay,
            customization
        } = this.state;

        if (this.state.shopCompany.length > 0) {
            let classNamePickup = 'clicli_order_choose_btn';
            let classNamePickup2 = 'row w-100 m-0 clicli_devivery clicli_order_card_wrap';
            if (pickupActive === true) {
                classNamePickup += ' active';
                classNamePickup2 += ' active';
            }
            let classNameDelivery = 'clicli_order_choose_btn';
            let classNameDelivery2 = 'row w-100 m-0 clicli_devivery';

            if (deliveryActive === true) {
                classNameDelivery += ' active';
                classNameDelivery2 += ' active';

            }
            return (<div className={classNamePickup2}>
                <div className="d-block">
                    <div className="">Магазины:</div>
                    {this.state.shopCompany.map(items => (
                        <div onClick={() => this.handaleClickShopActivti(items.id)}
                             className="custom-control custom-radio">
                            <input type="radio" id={items.id} name={items.id}
                                   value={items.id}

                                   className="custom-control-input"
                                   checked={this.state.shopCompanyActive === items.id}/>
                            <label className="custom-control-label"
                                   htmlFor={items.id}>{items.Address}<br/>{items.info}
                            </label>
                        </div>))}


                </div>
                <div className="d-block clicli_order_map">
                    <YMaps>
                        <div>
                            {console.log(this.state.ActiveShop.infoShop.pointCoordinates1, this.state.ActiveShop.infoShop.pointCoordinates2)}
                            <Map width="100%"
                                 height="200px" state={{
                                center: [this.state.ActiveShop.infoShop.coordinates1, this.state.ActiveShop.infoShop.coordinates2],
                                zoom: 16
                            }} defaultState={{
                                center: [this.state.ActiveShop.infoShop.coordinates1, this.state.ActiveShop.infoShop.coordinates2],
                                zoom: 10
                            }}>
                                <Placemark
                                    geometry={[this.state.ActiveShop.infoShop.pointCoordinates1, this.state.ActiveShop.infoShop.pointCoordinates2]}/>
                            </Map>
                        </div>
                    </YMaps>
                </div>
            </div>)
        }
    }

    name() {
        const {feedbackname, customization} = this.state;
        if (customization.name === true) {
            return (<div className="form-group  col-12 col-md-6">
                <label>ИП:</label>
                <input type="text" value={this.state.name} onChange={this.handleChangeName}
                       className={this.state.feedbacknameClass} required/>
                <div className="invalid-feedback">
                    {feedbackname}
                </div>
            </div>)
        }
    }
    promo() {
        const {feedbackname, customization} = this.state;
        if (customization.name === true) {
            return (<div className="form-group  col-12 col-md-6">
                <label>Промокод</label>
                <input type="text" value={this.state.promo} onChange={this.handleChangePromo}
                       className="form-control" />
                <div className="invalid-feedback">
                    {feedbackname}
                </div>
            </div>)
        }
    }


    surname() {
        const {feedbacksurname, customization} = this.state;
        if (customization.surname === true) {
            return (
                <div className="col-12 col-md-6 mb-3">
                    {/*<label>Фамилия:</label>*/}
                    <input hidden={true} type="text" value={this.state.surname}
                           onChange={this.handleChangeSurname} className="form-control"/>
                    <div className="invalid-feedback">
                        {feedbacksurname}
                    </div>
                </div>
            )
        }
    }

    phone() {
        const {customization} = this.state;
        if (customization.phone === true) {
            return (<div className="col-12 col-md-6 mb-3">
                <label>Номер телефона:<br/>&nbsp;  </label>
                <input type="phone" value={this.state.phone}
                       onChange={this.handleChangePhone}
                       className={this.state.feedbackphoneClass} required/>
                <div className="invalid-feedback">
                    {this.state.feedbackphone}
                </div>
            </div>)
        }
    }

    phoneCheck() {
        const {customization} = this.state;
        if (customization.phone === true) {
            console.log(this.state.CheckPhonePost)
            if (!this.state.CheckPhone) {
                return (<div className="col-12 col-md-6 mb-3">

                </div>)
            } else {
                if (this.state.CheckPhonePost) {
                    return (
                        <div className="row col-md-6 col-12">
                            <div className="col-5 col-md-5">

                                <input type="phone" placeholder={"4 цифры входящего номера"}

                                       className={this.state.feedbackphoneClass} required/>
                            </div>
                            <div className="col-7 col-md-7">

                                <div onClick={() => this.CheckPhoneApi()}
                                     className={this.state.seconds > '0' ? 'clicli_order_choose_btn ' : 'clicli_order_choose_btn active'}>Отправить
                                    повторно {this.state.seconds}
                                </div>
                            </div>
                        </div>)
                } else {
                    return (<div className="col-12 col-md-6 mb-3">
                        <label>На ваш телефон будет совершен звонок, укажите 4 последних цифры номера </label>
                        <div onClick={() => this.CheckPhoneApi()}
                             className='clicli_order_choose_btn active'>Отправить звонок
                        </div>
                    </div>)
                }

            }
        }
    }

    email() {
        const {customization} = this.state;
        if (customization.email === true) {
            return (<div className="col-12 col-md-6 mb-3">
                <label>Email: </label>
                <input type="email" value={this.state.email}
                       onChange={this.handleChangeEmail} className="form-control"/>
            </div>)
        }
    }

    comment() {
        const {customization} = this.state;
        if (customization.comment === true) {
            return (<div className="col-12 col-md-6 mb-3">
                <label>Комментарий: </label>
                <textarea className="form-control" value={this.state.comment}
                          onChange={this.handleChangeComment}/>

            </div>)
        }
    }

    render() {

        window.addEventListener('message', this.handleCookie);
        this.UpdateBasket();
        const {
            idShop,
            error,
            isLoaded,
            productsBasket,
            feedbacksurname,
            feedbackname,
            deliveryActive,
            pickupActive,
            PaymentOnSite,
            Online_pay,
            customization
        } = this.state;

        let classNamePickup = 'clicli_order_choose_btn';
        let classNamePickup2 = 'row w-100 m-0 clicli_devivery clicli_order_card_wrap';
        if (pickupActive === true) {
            classNamePickup += ' active';
            classNamePickup2 += ' active';
        }
        let classNameDelivery = 'clicli_order_choose_btn';
        let classNameDelivery2 = 'row w-100 m-0 clicli_devivery';

        if (deliveryActive === true) {
            classNameDelivery += ' active';
            classNameDelivery2 += ' active';

        }
        if (this.state.orderSuccessfull) {
            return (
                <div>
                    {/*<div className="clicli_main_shop_title">*/}
                    {/*    <h3 className="clicli_ordering_message_btitle">Заказ успешно отправлен</h3>*/}
                    {/*</div>*/}

                    <div className="clicli_main_shop_bg">
                        <div className="clicli_main_shop">
                            <div className="clicli_ordering_message">
                                <div className="position-relative" style={{position: "relative"}}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="clicli_ordering_message_btitle">Заказ успешно отправлен</h3>
                                            <p>

                                                {/*1. Менеджер проверит наличие товаров<br/>*/}
                                                {/*2. Рассчитает стоимость доставки<br/>*/}
                                                {/*3. Свяжется с вами и выставит счет*/}
                                            </p>
                                            <h3 className="clicli_ordering_message_stitle">Номер заказа: # {this.state.orderId}</h3>
                                            <h3 className="clicli_ordering_message_stitle">В ближайшее время с вами свяжется менеджер</h3>
                                            {/*<p className="mb-0">*/}
                                            {/*    Время работы: пн-пт 8.00-17.00<br/>*/}
                                            {/*    8 (800) 000 00 00<br/>*/}
                                            {/*    example@gmail.com*/}
                                            {/*</p>*/}
                                        </div>
                                    </div>
                                    <img src="https://www.flaticon.com/svg/static/icons/svg/946/946237.svg"
                                         className="clicli_ordering_block_img"/>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            )
        } else if (isLoaded == true) {
            console.log(this.state.ActiveShop)
            return (
                <div>
                    <div className="clicli_main_shop_title">
                        <h1>
                            Оформление заказа
                        </h1>
                    </div>

                    <div className="clicli_main_shop_bg">
                        <div className="clicli_main_shop">
                            <div className="clicli_card_wrap clicli_sm_wrap">
                                <div className="clicli_ordering_right">
                                    <div className="clicli_help_desktop">
                                        <img className="d-block clicli_call_center_img"
                                             src="/images/icons/call-center.png"/>
                                        Нужна помощь?<br/>
                                        <span className="font-weight-bold">{customization.phoneCompany}</span><br/>
                                        {customization.timeWorkCompany}
                                    </div>
                                    <div className="clicli_help_mobile">
                                        <div className="clicli_order_title">
                                            <div className="clicli_order_title_num">?</div>
                                            Нужна помощь?
                                        </div>
                                        <div className="clicli_order_step_wrap">
                                            <span className="font-weight-bold">{customization.phoneCompany}</span><br/>
                                            {customization.timeWorkCompany}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clicli_order_area">
                                <div className="clicli_order_title">
                                    <div className="clicli_order_title_num">1</div>
                                    Данные покупателя
                                </div>
                                <div className="clicli_order_step_wrap">
                                    <form className="form-row m-0 w-100">
                                        {this.name()}
                                        {this.promo()}
                                        {/*{this.surname()}*/}
                                        {this.phone()}
                                        {/*{this.phoneCheck()}*/}
                                        {this.email()}
                                        {this.comment()}
                                    </form>
                                </div>
                                {/*{<Delivery onChangeDelivery={this.onChangeDelivery} delivery={this.state.delivery}*/}
                                {/*           shop={this.state.shopCompany}/>}*/}

                                {/*<div className="clicli_order_title">*/}
                                {/*    <div className="clicli_order_title_num">3</div>*/}
                                {/*    Способ оплаты*/}
                                {/*</div>*/}
                                {/*<div className="clicli_order_step_wrap">*/}
                                {/*    {this.pay()}*/}
                                {/*    {this.textPay()}*/}

                                {/*    <div className={Online_pay ? 'clicli_online_pay  active' : 'clicli_online_pay '}>*/}
                                {/*        <p>После подверждения заказа, на вашу электронную почту вы получите ссылку на*/}
                                {/*            оплату, с помощью, которой сможете оплатить заказ Оплачивается вся сумма*/}
                                {/*            заказа.<br/></p>*/}
                                {/*    </div>*/}
                                {/*    <div className={PaymentOnSite ? 'clicli_online_pay  active' : 'clicli_online_pay'}>*/}
                                {/*        <div className="d-inline-block">*/}
                                {/*            <p></p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div*/}
                                {/*        className={this.state.BankTransfer ? 'clicli_online_pay active' : 'clicli_online_pay '}>*/}
                                {/*        <div className="d-inline-block">*/}
                                {/*            <small>(для физических лиц)</small>*/}
                                {/*            <p>Оплачивается вся сумма заказа.*/}

                                {/*                Вы получите подтверждение заказа и квитанцию для оплаты в банке.*/}
                                {/*                Квитанцию нужно оплатить в течение 3 банковских дней.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div*/}
                                {/*        className={this.state.CashlessPayments ? 'clicli_online_pay  active' : 'clicli_online_pay'}>*/}
                                {/*        <div className="d-inline-block">*/}
                                {/*            <small>(для юридических лиц)</small>*/}
                                {/*            <p>Оплачивается вся сумма заказа.*/}

                                {/*                Вы получите подтверждение заказа и счет на оплату. Счет нужно оплатить в*/}
                                {/*                течение 3 банковских дней.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="clicli_order_final clicli_order_step_wrap">
                                    <div className="font-weight-bold mb-3">
                                        Сумма: {this.state.FullPrice} ₽<br/>
                                        Товаров: {this.state.quantityProduct}<br/>
                                        Телефон: {this.state.phone}<br/>
                                        {/*Способ получения: {this.state.resultDelivery.activeDelivery}<br/>*/}
                                        {/*Оплата: {PaymentOnSite ? 'При получении' : 'Онлайн'}*/}
                                    </div>

                                    {this.handleClickPostBOrder()}


                                    <div className="clicli_confedenc">
                                        Подтверждая заказ, Вы соглашаетесь с условиями <i><b><a href="">политики
                                        конфиденциальности</a></b></i>
                                        и <i><b><a href="">правилами продажи</a></b></i>.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            )
        } else {
            return (<div>Загрузка...</div>)
        }

    }
}

export default Registration;