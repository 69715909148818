import React, {Component} from 'react';
import Registration  from '../registration/Registration';

class Basket extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            isLoaded_cat: false,
            productsFull: [],
            productsBasket: null,
            productsCategories: [],
            productsActive: [],
            categories: [],
            priceot: null,
            pricedo: null,
            categoriesActive: false,
            Cookie: null,
            // Cookie: '1d515266bcb227205c0e469aaea08317',
            FullPrice: 0,
            quantityProduct: 0,
            // idShop: '6fdcdb2edc968ee3ee2986b356aaa620'
            idShop: this.props.idShop

        };
        window.addEventListener('message', this.handleCookie);


        this.handleClickCategories = this.handleClickCategories.bind(this);
        this.handleClickDeleteBasket = this.handleClickDeleteBasket.bind(this);
    }

    handleClickCategories(id) {
        if (id == null) {
            let temp = this.state.productsFull
            this.setState({
                productsActive: temp,
                productsCategories: null,
                categoriesActive: false,
                priceot: '',
                pricedo: '',
            });
        } else {
            let temp = this.state.productsFull
            const temp1 = temp.filter(item => item.categories.id == id);
            this.setState({
                productsActive: temp1,
                productsCategories: temp1,
                categoriesActive: true
            });
        }

    }

    handleClickUPProductBasket(idProduct) {
console.log('090')
        let urlAPI = 'https://api.cliclishop.ru/widget/basket/update/UP';
        let quantityProduct;

        const data = {hash: this.state.Cookie, id: idProduct};

        fetch(urlAPI, {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
        })
            .then(res => res.json())
            .then(
                (result) => {

                    // quantityProduct = result.quantityProduct
                    // let request = this.state.productsBasket.find(({id}) => id === idProduct);
                    // request.quantity = quantityProduct;
                    // console.log(request)
                    // const newList = this.state.productsBasket.map(o => {
                    //     if (o.id === request.id) {
                    //         return request;
                    //     }
                    //     return o;
                    // });
                    this.setState(
                        {
                            productsBasket: result.product,
                            FullPrice: result.FullPrice,
                            quantityProduct: result.quantityProduct,
                            isLoaded: true,


                        });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )

        const temp1 = this.state.productsBasket.filter(item => item.id = idProduct);


    }

    handleClickDNProductBasket(idProduct) {
        let urlAPI = 'https://api.cliclishop.ru/widget/basket/update/DN';
        let quantityProduct;

        const data = {hash: this.state.Cookie, id: idProduct};

        fetch(urlAPI, {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
        })
            .then(res => res.json())
            .then(
                (result) => {

                    // quantityProduct = result.quantityProduct
                    // let request = this.state.productsBasket.find(({id}) => id === idProduct);
                    // request.quantity = quantityProduct;
                    // console.log(request)
                    // const newList = this.state.productsBasket.map(o => {
                    //     if (o.id === request.id) {
                    //         return request;
                    //     }
                    //     return o;
                    // });
                    // console.log(newList)
                    this.setState(
                        {
                            productsBasket: result.product,
                            FullPrice: result.FullPrice,
                            quantityProduct: result.quantityProduct,
                            isLoaded: true,


                        });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )

    }



    handleClickDeleteBasket(id) {
        let urlAPI = 'https://api.cliclishop.ru/widget/basket/delete/';


        const data = {hash: this.state.Cookie, id: id};

        fetch(urlAPI, {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
        })
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState(
                        {
                            productsBasket: result.product,
                            FullPrice: result.FullPrice,
                            quantityProduct: result.quantityProduct,
                            isLoaded: true,


                        });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }



    handleCookie = (e) => {

        if (e.data.hasOwnProperty("Cookie")) {

            this.setState({
                Cookie: e.data.Cookie,
            });

        }
    }

    UpdateBasket() {

        if (this.state.Cookie != null) {

            if (this.state.productsBasket === null) {
                let urlAPI = 'https://api.cliclishop.ru/widget/basketShow/' + this.state.Cookie;

                fetch(urlAPI)
                    .then(res => res.json())

                    .then(
                        (result) => {
                            console.log(result)
                            this.setState(
                                {
                                    productsBasket: result.product,
                                    FullPrice: result.FullPrice,
                                    quantityProduct: result.quantityProduct,
                                    isLoaded: true,


                                });

                        },
                        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                        // чтобы не перехватывать исключения из ошибок в самих компонентах.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )

            }
        }


    }
    ClickOrder()
    {

        return(<Registration/>)
    }
    showProduct() {
        const {idShop, error, isLoaded, productsBasket, categories, priceot, pricedo} = this.state;

        function showImage(image) {

            if (image) {
                return (
                    <img src={image.link} className="clicli_cart_product_item_img"/>

                )
            }
            else{
                return (
                    <img src="/images/icons/no_image.png" className="clicli_cart_product_item_img"/>

                )
            }
        }

        if (productsBasket != null) {
            if(productsBasket.length != 0){
                return (
                    productsBasket.map(items => (
                        <div className="clicli_cart_product_item">
                            <div className="h-100 d-inline-block">
                                {showImage(items.product.image)}
                                {console.log(items.product)}
                            </div>
                            <div className="d-inline-block w-100 align-self-center clicli_sm_pt15" style={{padding: 15}}>
                                {items.product.name}<br/>
                                {items.attributes.title}
                            </div>
                            <div className="d-inline-block w-100 align-self-center clicli_sm_pt15" style={{padding: 15}}>
                                Стоимость: {items.attributes.value}
                            </div>
                            <div className="float-right h-100 align-self-center clicli_sm_pt15 clicli_sm_w100" >
                                <div onClick={() => this.handleClickDeleteBasket(items.id)}
                                     className="float-left mb-3 form-control-sm clicli_delete_text">
                                    <span className="clicli_delete_red_text">Удалить</span>
                                </div>
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button onClick={() => this.handleClickDNProductBasket(items.attributes
                                        .id)} type="button"
                                            className="btn clicli_plus_minus_btn" style={{maxWidth: 224}}> -
                                    </button>
                                    <input type="number" min="1" className="btn clicli_num_products"
                                           value={items.quantity}/>
                                    <button onClick={() => this.handleClickUPProductBasket(items.attributes
                                        .id)} type="button"
                                            className="btn clicli_plus_minus_btn">+
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                );
            }else{
                return ( <div style={{height:'100%'}}><h3 className='text-center'>Ваша корзина пуста!</h3></div>);



        }

        }
    }
    activeButton(){
        if(this.state.FullPrice>0){
            return(<a href={'/order/'+this.state.idShop}>
                <div  className="clicli_product_arrange_button" style={{maxWidth: 224}}>
                    <div className="clicli_result_title text-center">Оформить</div>
                </div>
            </a>)
        }
    }
    componentWillMount() {
        window.addEventListener('message', this.handleCookie);


    }

    componentDidMount() {


    }

    render() {

        window.addEventListener('message', this.handleCookie);
        this.UpdateBasket();
        const {idShop, error, isLoaded, productsBasket, categories, priceot, pricedo} = this.state;


        return (
            <div >
                <div  className="clicli_main_shop_title">
                    <h1>
                        Корзина
                    </h1>
                </div>

                <div className="clicli_main_shop_bg">
                    <div className="clicli_main_shop">
                        <div className="clicli_card_wrap clicli_sm_rezult_cart">
                            <div className="clicli_card_result float-right">
                                <div className="clicli_result_title">Итого:</div>
                                <div className="font-weight-bold">товаров {this.state.quantityProduct} на сумму</div>
                                <div className="clicli_result_title font-weight-bold">{this.state.FullPrice} ₽</div>

                                {this.activeButton()}
{/*<input className={'mt-5 p-3'} placeholder={'Введите промокод'} type={'string'}/>*/}
                            </div>
                        </div>
                        <div className="clicli_cart_products_area">
                            {this.showProduct()}


                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Basket;