import React, {Component} from 'react';


export class DetailProduct extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isLoaded_cat: false,
            productsFull: [],
            productsCategories: [],
            productsActive: [],
            productsBasket: null,
            categories: [],
            images: [],
            specifications: [],
            attrproducts: [],
            imageActive: [],
            priceot: null,
            pricedo: null,
            activeAttr: null,
            categoriesActive: false,
            idShop: this.props.idShop,
            IdProduct: this.props.IdProduct,
            Cookie: this.props.Cookie,
        };
        // this.handleChangePriceOT = this.handleChangePriceOT.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        window.addEventListener('message', this.handleCookie);
        this.handleClickAddBasket = this.handleClickAddBasket.bind(this);
        this.checkProductToBasket = this.checkProductToBasket.bind(this);
        this.setActiveAttr = this.setActiveAttr.bind(this);
        this.handleCookie = this.handleCookie.bind(this);
    }

    checkProductToBasket(idProduct) {

        if (this.state.productsBasket) {
            let temp1 = null;

            console.log(this.state.productsBasket)
            temp1 = this.state.productsBasket.find(item => item.product.id === idProduct);
            // let request = this.state.productsBasket.find(({id}) => id.product.id === idProduct);

            if (temp1) {
                return ('В корзине')
            } else {
                return ('В корзину')
            }

        }

    }

    handleCookie = (e) => {

        if (e.data.hasOwnProperty("Cookie")) {
            this.setState({
                Cookie: e.data.Cookie,
            });
        }
    }

    handleClickImage(e) {
        this.setState({
            imageActive: e
        });
    }

    ReloadBasket() {
        if (this.state.Cookie != null) {
            let urlAPI = 'https://api.cliclishop.ru/widget/basketShow/' + this.state.Cookie;

            fetch(urlAPI)
                .then(res => res.json())
                .then(
                    (result) => {

                        this.setState(
                            {
                                productsBasket: result.product,
                                FullPrice: result.FullPrice,
                                quantityProduct: result.quantityProduct,



                            });

                    },
                    // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                    // чтобы не перехватывать исключения из ошибок в самих компонентах.
                    (error) => {
                        this.setState({

                            error
                        });
                    }
                )
        }
    }

    handleClickAddBasket(id) {


        let urlAPI = 'https://api.cliclishop.ru/widget/basket/add/';


        const data = {hash: this.state.Cookie, id: id, idAttr: this.state.activeAttr};

        fetch(urlAPI, {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
        })
            .then(res => res.json())
            .then(
                (result) => {

                    this.ReloadBasket();
                    this.setState(
                        {
                            isLoaded: true,


                        });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )

    }

    componentDidMount() {


        let urlAPI = 'https://api.cliclishop.ru/api/widget/' + this.state.idShop + '/product/' + this.state.IdProduct;

        fetch(urlAPI)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)

                    if (result.products.attrproducts) {
                        this.setState(
                            {
                                activeAttr: result.products.attrproducts[0].id,
                            });
                    }
                    this.setState(
                        {

                            isLoaded: true,
                            productsActive: result.products,
                            attrproducts: result.products.attrproducts,
                            imageActive: result.products.image ? result.products.image.link : null,
                            images: result.image,
                            specifications: result.specifications,


                        });

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
        if (this.state.priceot == null || this.state.pricedo == null) {
            this.setState({
                productsActive: this.state.productsFull
            });
        }

    }

    UpdateBasket() {

        if (this.state.Cookie != null) {

            if (this.state.productsBasket === null) {
                let urlAPI = 'https://api.cliclishop.ru/widget/basketShow/' + this.state.Cookie;

                fetch(urlAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            this.setState(
                                {
                                    productsBasket: result.product,
                                    FullPrice: result.FullPrice,
                                    quantityProduct: result.quantityProduct,



                                });

                        },
                        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                        // чтобы не перехватывать исключения из ошибок в самих компонентах.
                        (error) => {
                            this.setState({

                                error
                            });
                        }
                    )

            }
        }


    }

    setActiveAttr(id) {
        this.setState(
            {
                activeAttr: id,
            });
        console.log(id)
    }

    price() {
        const {idShop, error, isLoaded, productsActive, specifications, images, imageActive, attrproducts} = this.state;
console.log(productsActive)
        if (productsActive.attrproducts.length > 0) {
            if (productsActive.attrproducts.length == 1) {
                return (productsActive.attrproducts[0].value)
            } else {
                return productsActive.attrproducts[0].value + ' - ' + productsActive.attrproducts[productsActive.attrproducts.length - 1].value
            }
        } else {
            return ('Цена не указана')
        }
    }

    render() {
        const {idShop, error, isLoaded, productsActive, specifications, images, imageActive, attrproducts} = this.state;
        this.UpdateBasket();

        if (isLoaded) {
            {console.log('+')}
            const IMGActiveStyle = {backgroundImage: `url(${imageActive})`}
            return (
                <div className="row m-0 ">
                    <div className='col-12'>
                        <a className='d-flex' target="_blank" href="https://start1.cliclishop.ru/basket/">
                            <div className="media clicli_product_cart_button clicli_product_item_card">
                                <img className="clicli_product_cart_image"
                                     src="/images/icons/supermarket.png" alt="Корзина"/>
                                <div className="float-left">Корзина</div>
                            </div>
                        </a>
                    </div>

                    <div className="col-12 col-xl-6 clicli_product_imgs_area">
                        <div className='clicli_centered_product_slider'>
                            <div className="clicli_product_activ_img" style={IMGActiveStyle}></div>
                            <div className="d-inline-block position-relative clicli_product_min_imgs">
                                <div className="w-100 m-auto text-center clicli_product_min_imgs_arr_btn">
                                    <img src="/images/icons/arrow.png" className="clicli_product_min_imgs_top"/>
                                </div>
                                <div className="clicli_product_min_imgs_wr ">
                                    {images.map(items => (
                                        <div onClick={() => this.handleClickImage(items.link)}
                                             className="clicli_product_min_img"
                                             style={{backgroundImage: `url(${items.link})`}}></div>
                                    ))}
                                    {/*<div className="clicli_product_min_img active"*/}
                                    {/*     style={{backgroundImage: "url('images/cat1.png')"}}></div>*/}
                                </div>
                                <div className="w-100 position-absolute text-center clicli_product_min_imgs_arr_btn">
                                    <img className="clicli_product_min_imgs_bottom" src="/images/icons/arrow.png"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-xl-6 clicli_product_main_descr">
                        <div>
                            <div className="clicli_title27 font-weight-bold">{productsActive.name}
                            </div>

                            <div className="clicli_product_price font-weight-bold">
                                {this.price()}

                            </div>
                            Варианты товара:<br/>
                            <form>
                                {attrproducts ?
                                    attrproducts.map(items => (
                                        <div className="checkbox">
                                            <label className="custom-checkbox">
                                                <input onClick={() => this.setActiveAttr(items.id)} type="radio"
                                                       name="radio" value={items.id}
                                                       checked={this.state.activeAttr === items.id}/>
                                                <span> {items.title} - {items.value}<br/></span>
                                            </label>
                                        </div>

                                    )) : ''}
                            </form>
                            {/*<p className='font-weight-bold'>Артикул GHT93</p>*/}
                            {/*<p className='font-weight-bold'>Код товара 1000099631</p>*/}
                            {console.log()}
                            <div onClick={() => this.handleClickAddBasket(productsActive.id)}
                                 className="clicli_product_btn">{this.checkProductToBasket(productsActive.id)}
                            </div>
                            <div className="clicli_product_btn ml-2">
                                <img src="/images/icons/share.png" className="clicli_product_share_icon"/>
                            </div>
                            <br/>
                            <small>Цены в интернет-магазине могут отличаться от цен в розничных магазинах</small>
                        </div>
                    </div>

                    <div className="col-12 clicli_tab">
                        <div className="m-auto">

                            <ul className="nav nav-tabs" id="cliclitab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active font-weight-bold" id="home-tab"
                                       data-toggle="tab"
                                       href="#home"
                                       role="tab" aria-controls="home" aria-selected="true">Описание</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link  font-weight-bold" id="profile-tab"
                                       data-toggle="tab"
                                       href="#profile"
                                       role="tab" aria-controls="profile"
                                       aria-selected="false">Характеристики</a>
                                </li>
                            </ul>
                            <div className="tab-content clicli_padding_tab" id="clicliTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel"
                                     aria-labelledby="home-tab">
                                    {productsActive.content}
                                </div>
                                <div className="tab-pane fade " id="profile" role="tabpanel"
                                     aria-labelledby="profile-tab">
                                    {specifications.map(items => (
                                        <div className="mb-3 clearfix">
                                <span className="clicli_characteristic_left">
                                    {items.name}
                                </span>
                                            <span className="clicli_characteristic_right">
                                    {items.content}
                                </span>
                                        </div>

                                    ))}

                                    <div className="clearfix"></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        } else {
            console.log('-')
            return (<div className="preloader">
                <div className="preloader__row">
                    <div className="preloader__item"></div>
                    <div className="preloader__item"></div>
                </div>
            </div>)
        }
    }


}

export default DetailProduct;