import React, {Component} from 'react';
// import Test from "../test/Test";
import DetailProduct from "../product/DetailProduct";
// import ReactModal from 'react-modal';
import Modal from "./Modal";
class Shop extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            IdProductActive: null,
            detailProductActive: null,
            isLoaded_cat: false,
            productsFull: [],
            productsBasket: null,
            productsCategories: [],
            productsCategoriesChildren: [],
            productsActive: [],
            categories: [],
            page: [],
            priceot: null,
            pricedo: null,
            sort: null,
            search: null,
            categoriesActive: false,
            Cookie: null,
            idShop: '8dd863ac84a268292a822b4300361197',
            // idShop: this.props.idShop,
            displayChild: null,
            Breadcrumbs1: null,
            Breadcrumbs2: null,
            detailProductActiveName: null,
            Breadcrumbs1ID: null,
            Breadcrumbs2ID: null,
            showModal: false,
            idPageActive:'',
            LinkBasket:'',
            imgFullProduct:'',
        };
        window.addEventListener('message', this.handleCookie);
        this.handleChangePriceOT = this.handleChangePriceOT.bind(this);
        this.handleChangePriceDO = this.handleChangePriceDO.bind(this);
        this.handleClickCategories = this.handleClickCategories.bind(this);
        this.handleClickAddBasket = this.handleClickAddBasket.bind(this);
        this.checkProductToBasket = this.checkProductToBasket.bind(this);
        this.handleChangeSort = this.handleChangeSort.bind(this);
        this.productActive = this.productActive.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleCookie = this.handleCookie.bind(this);
        this.menuNode = this.menuNode.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal (idPageActive) {

        this.setState({ idPageActive:idPageActive,showModal: true });
    }

    handleCloseModal () {
        this.setState({ idPageActive:'',showModal: false });
    }

    handleClickAddBasket(id) {
        let urlAPI = 'https://api.cliclishop.ru/widget/basket/add/';
        const data = {hash: this.state.Cookie, id: id};
        fetch(urlAPI, {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.ReloadBasket();
                    this.setState(
                        {
                            isLoaded: true,
                        });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    handleClickCategories(id) {

        if (id === -1) {
            let temp = this.state.productsFull;
            this.setState({
                productsActive: temp,
                productsCategories: temp,
                categoriesActive: false,
                priceot: '',
                pricedo: '',
                sort: 'default',
                search: '',
                Breadcrumbs1: null,
                Breadcrumbs2: null,
                detailProductActive: null,
                detailProductActiveName: null,
            });
        } else if (id == null) {

            let temp = this.state.productsFull;
            this.setState({
                productsActive: temp,
                productsCategories: temp,
                categoriesActive: true,
                priceot: '',
                pricedo: '',
                sort: 'default',
                search: '',
                Breadcrumbs1: null,
                Breadcrumbs2: null,
                detailProductActive: null,
                detailProductActiveName: null,
            });
        } else {
            let temp = this.state.productsFull;
            let temp2 = this.state.categories;

            const temp1 = temp.filter(item => item != null && item.categories.id === id);
            const temp3 = temp2.filter(item => item.id === id);

            this.setState({
                productsActive: temp1,
                productsCategories: temp1,
                categoriesActive: true,
                sort: 'default',
                productsCategoriesChildren: temp3[0].categories,
                detailProductActive: null,
                detailProductActiveName: null,
            });
            if (temp3[0].categories.length === 0) {
                if (!temp3[0].parent) {
                    this.setState({
                        Breadcrumbs2: null,
                        Breadcrumbs1: temp3[0].name,
                        Breadcrumbs1ID: temp3[0].id
                    });
                } else {
                    let temp5 = temp.filter(item => item.categories.id === id);
                    this.setState({
                        Breadcrumbs2: temp3[0].name,
                        Breadcrumbs1: temp3[0].parent.name,
                        Breadcrumbs1ID: temp3[0].parent.id,
                        Breadcrumbs2ID: temp3[0].id,
                    });
                }
            }
            if (temp3[0].categories.length > 0) {
                let array1;
                let array2 = temp3[0].categories.flatMap(items => temp.filter(item => item.categories.id == items.id))
                this.setState({
                    productsCategories: array2,
                    productsActive: array2,
                    Breadcrumbs1: temp3[0].name,
                    Breadcrumbs2: '',
                    Breadcrumbs1ID: temp3[0].id,
                    Breadcrumbs2ID: null,
                });
            }
        }
    }

    handleChangePriceOT(event) {
        this.setState({priceot: event.target.value});
        let temp;
        if (this.state.categoriesActive) {
            temp = this.state.productsCategories
        } else if (!this.state.categoriesActive) {
            temp = this.state.productsFull
        }
        const temp1 = temp.filter(item => item.price >= event.target.value);
        if (this.state.pricedo == null || this.state.pricedo == '') {
            this.setState({productsActive: temp1});
        } else {
            const temp2 = temp1.filter(item => item.price <= this.state.pricedo);
            this.setState({productsActive: temp2});
        }
    }

    handleChangePriceDO(event) {
        this.setState({pricedo: event.target.value});

        let temp;
        if (this.state.categoriesActive) {
            temp = this.state.productsCategories
        } else if (!this.state.categoriesActive) {
            temp = this.state.productsFull
        }
        if (event.target.value == '') {
            this.setState({productsActive: temp});
        } else {
            const temp1 = temp.filter(item => item.price <= event.target.value);

            if (this.state.priceot == null) {
                this.setState({productsActive: temp1});
            } else {
                const temp2 = temp1.filter(item => item.price >= this.state.priceot);
                this.setState({productsActive: temp2});
            }
        }
    }

    handleCookie = (e) => {
        if (e.data.hasOwnProperty("Cookie")) {
            this.setState({
                Cookie: e.data.Cookie,
            });
        }
    }
    price(product) {
        const {idShop, error, isLoaded, productsActive, specifications, images, imageActive, attrproducts} = this.state;

        if (product.attrproducts.length > 0) {
            if (product.attrproducts.length == 1) {
                return (product.attrproducts[0].value)
            } else {
                return product.attrproducts[0].value + ' - ' + product.attrproducts[product.attrproducts.length - 1].value
            }
        } else {
            return ('Цена не указана')
        }
    }
    componentDidMount() {
        let urlAPI = 'https://api.cliclishop.ru/api/widget/' + this.state.idShop;
        let Cookie = 'default';
        fetch(urlAPI)
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState(
                        {
                            isLoaded: true,
                            LinkBasket: result.LinkBasket,
                            imgFullProduct: result.imgFullProduct,
                            productsActive: result.products,
                            productsFull: result.products,
                            categories: result.categories,
                            page: result.page,
                        });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
        if (this.state.priceot == null || this.state.pricedo == null) {
            this.setState({
                productsActive: this.state.productsFull
            });
        }
        window.addEventListener('message', this.handleCookie);
    }

    checkProductToBasket(idProduct) {
        if (this.state.productsBasket) {
            let temp1 = null;
            temp1 = this.state.productsBasket.find(item => item.product.id === idProduct);
            // let request = this.state.productsBasket.find(({id}) => id.product.id === idProduct);
            if (temp1) {
                return ('В корзине')
            } else {
                return ('В корзину')
            }
        }

    }

    productActive(Product) {

        this.setState({
            detailProductActive: Product.id,
            detailProductActiveName: Product.name
        });
    }

    ReloadBasket() {
        if (this.state.Cookie != null) {
            let urlAPI = 'https://api.cliclishop.ru/widget/basketShow/' + this.state.Cookie;
            fetch(urlAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState(
                            {
                                productsBasket: result.product,
                                FullPrice: result.FullPrice,
                                quantityProduct: result.quantityProduct,
                                isLoaded: true,
                            });
                    },
                    // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                    // чтобы не перехватывать исключения из ошибок в самих компонентах.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    handleSearch(event) {
        this.setState({search: event.target.value});
        var filteredList = this.state.productsFull.filter(function (item) {
            return item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.setState({productsActive: filteredList});
    }

    handleChangeSort(event) {
        this.setState({sort: event.target.value});
    }


    UpdateBasket() {
        if (this.state.Cookie != null) {
            if (this.state.productsBasket === null) {
                let urlAPI = 'https://api.cliclishop.ru/widget/basketShow/' + this.state.Cookie;
                fetch(urlAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState(
                                {
                                    productsBasket: result.product,
                                    FullPrice: result.FullPrice,
                                    quantityProduct: result.quantityProduct,
                                    isLoaded: true,
                                });
                        },
                        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                        // чтобы не перехватывать исключения из ошибок в самих компонентах.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
            }
        }
    }


    menuNode() {
        if (this.state.categoriesActive === false) {
            let categories1 = this.state.categories.filter(item => item.parent == null);
            return (
                <div className="clicli_products_area">
                    <div className="w-100 clicli_menu_catalog">
                        <div className="row m-0 p-0">
                            {categories1.map(items => (
                                <div className="clicli_cat float-left">
                                    <div className="clicli_category_card h-100 text-center"
                                         onClick={() => this.handleClickCategories(items.id)}>
                                        <div className="clicli_category_card_t_img">
                                            <img className="clicli_category_card_img" src={items.image? items.image:'/images/icons/no_image.png'}/>
                                        </div>
                                        <div className="w-100 clicli_cat_text">
                                            <div className="mb-2">
                                                <span className='clicli_category_name'>
                                                    {items.name}
                                                </span>
                                            </div>
                                            {/*<ul>*/}
                                            {/*    {items.categories.map(items => (*/}
                                            {/*        <li onClick={() => this.handleClickCategories(items.id)}>*/}
                                            {/*            <span>{items.name}</span>*/}
                                            {/*        </li>*/}
                                            {/*    ))}*/}
                                            {/*</ul>*/}
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            ))}
                            <div className="clicli_cat float-left"
                                 onClick={() => this.handleClickCategories(null)}>
                                <div className="clicli_category_card h-100 text-center">
                                    <div className="clicli_category_card_t_img">
                                        <img className="clicli_category_card_img"
                                             src={this.state.imgFullProduct}/>
                                    </div>
                                    <div className="w-100">
                                        <div className="mb-2 clicli_category_name text-center">
                                            <a href='#all'>Все товары</a>
                                        </div>
                                    </div>
                                    {/*<div className="float-right clicli_category_card_r_img">*/}
                                    {/*    <img className="clicli_category_card_img"*/}
                                    {/*         src="https://image.cliclishop.ru/img/https_avatars_mds_yandex_net_get_mpic_175985_img_id8503076707942106480_jpeg_orig_307-380x380.jpg"/>*/}
                                    {/*</div>*/}
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div className="d-inline-block text-center clearfix">
                        </div>
                    </div>
                </div>)
        } else {
            const {idShop, error, isLoaded, productsActive, categories, Breadcrumbs1ID, Breadcrumbs2ID, sort} = this.state;

            let array = this.state.productsActive
            if (sort == 'default') {
                productsActive.sort(function (a, b) {
                    return b.id - a.id
                })
            }
            if (sort == 'сheaper') {
                productsActive.sort(function (a, b) {
                    return a.price - b.price
                })
            }
            if (sort == 'expensive') {
                productsActive.sort(function (a, b) {
                    return b.price - a.price
                })
            }
            if (sort == 'alphabeticallyStart') {
                productsActive.sort(function (a, b) {
                    var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                    if (nameA < nameB) //сортируем строки по возрастанию
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 // Никакой сортировки
                })
            }
            if (sort == 'alphabeticallyEnd') {
                productsActive.sort(function (a, b) {
                    var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                    if (nameA > nameB) //сортируем строки по возрастанию
                        return -1
                    if (nameA < nameB)
                        return 1
                    return 0 // Никакой сортировки
                })
            }
            return (
                <div className="clicli_products_area">
                    <div className="w-100 clicli_products">
                        {productsActive.map(items => (
                            <div className="clicli_product_list_item">
                                <div className="clicli_product_card">
                                    <a onClick={() => this.productActive(items)}>
                                        <div className="clicli_product_card_img_w"
                                             style={items.image?{backgroundImage: "url(" + items.image.link + ")"}:{backgroundImage: "url('/images/icons/no_image.png')"}}>
                                        </div>
                                    </a>
                                    <div className="w-100 clicli_product_card_description ">
                                        <a onClick={() => this.productActive(items)}>
                                            <div className="clicli_product_card_title">
                                                {items.name}
                                            </div>
                                        </a>
                                        <div className="clicli_product_card_price">

                                            {this.price(items)}


                                        </div>
                                        <div onClick={() => this.productActive(items)}
                                             className="clicli_product_card_add_button">Перейти
                                        </div>
                                        <a href="">
                                            <div className="clicli_product_card_share_button">
                                                <img className="clicli_share_icon"
                                                     src="/images/icons/share.png"
                                                     alt="Поделиться"/>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

    }

    toggleAboutStoreArea() {
        let aboutStore = document.getElementById('aboutStoreArea');
        if (aboutStore != null) {
            aboutStore.classList.toggle('d-none');
        }

        let clicli_arrow_down = document.getElementById('clicli_arrow_down');
        if (clicli_arrow_down != null) {
            clicli_arrow_down.classList.toggle('clicli_transl_scale');
        }
    }

    toggleFilters() {
        let clicli_filters = document.getElementById('clicli_filters');
        if (clicli_filters != null) {
            clicli_filters.classList.toggle('clicli_937_toggle');
        }

        let clicli_filter_icon_close = document.getElementById('clicli_filter_icon_close');
        let clicli_filter_icon = document.getElementById('clicli_filter_icon');
        if (clicli_filter_icon != null) {
            clicli_filter_icon.classList.toggle('d-none');
        }
        if (clicli_filter_icon_close != null) {
            clicli_filter_icon_close.classList.toggle('d-none');
            clicli_filter_icon_close.classList.toggle('clicli_active_filters');
        }
    }

    render() {
        const {detailProductActive, error, isLoaded, productsActive, categories, Breadcrumbs1ID, Breadcrumbs2ID, sort} = this.state;
        window.addEventListener('message', this.handleCookie);
        this.UpdateBasket();
        return (
            <div>
                {/*<ReactModal*/}
                {/*    isOpen={this.state.showModal}*/}
                {/*    contentLabel="Minimal Modal Example">*/}
                {/*    <button className="clicli_product_card_add_button" onClick={this.handleCloseModal}>Close Modal</button>*/}
                {/*    <br/>*/}



                {/*    Доставка товара в рамках города Йошкар-Ола осуществляется в течение суток*. <br/>*/}
                {/*    <br/>*/}
                {/*    При оформлении "товара под заказ" доставка осуществляется в течении 1-х суток* после прихода товара на магазин. <br/>*/}
                {/*    Бесплатный подъём на этаж. <br/>*/}
                {/*    *Доставка не осуществляется по понедельникам. <br/>*/}
                {/*    <br/>*/}
                {/*    Для крупногабаритной техники при невозможности использовать лифт, подъем до 5 этажа - 3 единицы техники бесплатно, свыше 5-го этажа оплачивается дополнительно, 50 рублей за каждый этаж и за каждую единицу крупногабаритного товара, стоимость каждой последующей единицы подъема Крупногабаритной техники – 100 рублей) <br/>*/}
                {/*    <br/>*/}
                {/*    Большинство технически сложных товаров требует до начала их использования Вашего ознакомления с руководством по эксплуатации. <br/>*/}
                {/*    <br/>*/}
                {/*    В зимнее время года передача товара сопряжена с резким изменением температурного режима, поэтому немедленное включение электронной техники является нарушением условий эксплуатации. <br/>*/}
                {/*    <br/>*/}
                {/*    а) если обратная телефонная связь с Клиентом отсутствует, доставка товара переносится на следующую дату. <br/>*/}
                {/*    <br/>*/}
                {/*    б) в случае отсутствия Клиента по указанному  адресу доставки в назначенное время, Клиенту необходимо обратиться за информацией в магазин. Повторная доставка оформляется и оплачивается Клиентом отдельно. <br/>*/}
                {/*    <br/>*/}
                {/*    Услуга "доставка" может быть перенесена на другой день по инициативе Клиента, но не менее чем, за 6 часов до времени указанного Клиентом в Заявке на доставку. <br/>*/}
                {/*    Доставленный Товар передается Клиенту на основании предъявленного оригинала кассового (фискального) чека. Лицам не достигшим совершеннолетия товар не выдаётся. <br/>*/}
                {/*    Доставка осуществляется при беспрепятственном подъездном пути к подъезду дома Клиента. <br/>*/}
                {/*    Упакованный Товар заносится за порог помещения, только если:*/}
                {/*    - подготовлены условия для беспрепятственного доступа сотрудников службы доставки: освобождены проходы и лестничные пролёты и т.д. <br/>*/}
                {/*    <br/>*/}
                {/*    - не потребуется перестановка мебели или других предметов <br/>*/}
                {/*    <br/>*/}
                {/*    - расстояние между дверной коробкой или стеной и каждой стороной товара в упаковке не меньше 10 см. <br/>*/}
                {/*    <br/>*/}
                {/*    - В случае если габариты товара в упаковке превышают размеры лестничных маршей, площадок, дверей лифта и входных дверей, товар будет передан Вам перед подъездом. <br/>*/}
                {/*    <br/>*/}
                {/*    Вам необходимо проверить внешний вид и комплектность Товара. <br/>*/}
                {/*    - проверить комплектность всего заказа и каждого товара по отдельности <br/>*/}
                {/*    <br/>*/}
                {/*    - убедиться в работоспособности ручек, защёлок и других механических узлов <br/>*/}
                {/*    <br/>*/}
                {/*    - убедиться в отсутствии на Товаре механических повреждений и если у Вас не возникает претензий подписать Акт приема передачи с указанием Ф.И.О. и даты получения Товара. <br/>*/}
                {/*    <br/>*/}
                {/*    После подписания клиентом Акта приема-передачи претензии по внешнему виду и комплектации НЕ ПРИНИМАЮТСЯ!!! Покупатель самостоятельно несет ответственность за внешний вид и комплектацию товара после получения и подписания наряда на выдачу товара. <br/>*/}
                {/*    <br/>*/}
                {/*    Проверка работоспособности Товара сотрудниками доставки не производится. <br/>*/}
                {/*    Консультации по вопросам технических параметров и функциональных особенностей товаров, их совместимости, стоимости и т.п. сотрудники службы доставки не выполняют; <br/>*/}
                {/*    Дополнительные услуги сотрудниками доставки не осуществляются. <br/>*/}
                {/*    Если в день доставки Вы отказываетесь от заказа менее чем за 2 часа до приезда курьера, следующая доставка товара возможна только при повторной оплате стоимости доставки. <br/>*/}
                {/*    <br/>*/}
                {/*    Желаем Вам приятных покупок! <br/>*/}

                {/*    <Modal idPage={this.state.idPageActive}/>*/}
                {/*</ReactModal>*/}
                <div className="clicli_main_shop_bg">
                    <div className="clicli_main_shop">
                        <nav aria-label="breadcrumb" className='clicli_desktop_breadcrumbs'>
                            <ol className="breadcrumb">
                                <li onClick={() => this.handleClickCategories(-1)} className="breadcrumb-item">
                                    <img src="/images/icons/home_page.png" className="clicli_icon_breadcrumb"
                                         alt="Каталог"/>
                                    <span>Каталог</span>
                                </li>
                                {this.state.Breadcrumbs1 ?
                                    <li onClick={() => this.handleClickCategories(Breadcrumbs1ID)}
                                        className="breadcrumb-item">
                                        <span>{this.state.Breadcrumbs1}</span>
                                    </li> : ''}
                                {this.state.Breadcrumbs2 ?
                                    <li onClick={() => this.handleClickCategories(Breadcrumbs2ID)}
                                        className="breadcrumb-item" aria-current="page">
                                        <span>{this.state.Breadcrumbs2}</span>
                                    </li> : ''}
                                {this.state.detailProductActive ?
                                    <li className="breadcrumb-item"
                                        aria-current="page">
                                        <span>{this.state.detailProductActiveName}</span>
                                    </li> : ''}
                            </ol>
                        </nav>
                        {detailProductActive ?
                            <DetailProduct Cookie={this.state.Cookie}
                                           idShop={this.state.idShop}
                                           IdProduct={this.state.detailProductActive}/> :
                            <div className="w-100">
                                <div className="clicli_left_menu d-inline-block">

                                    {/*О магазине на маленьких экранах*/}
                                    <div className='clicli_937 clicli_mb_28'>
                                        <div className='float-right '>
                                            <span className='clicli_help_text'>пн-пт 8:00-18:00</span><br/>
                                            {/*<a className='float-right font-weight-bold clicli_info_number'*/}
                                            {/*   href='#'>8-800-555-55-55</a>*/}
                                        </div>
                                        <div className='float-left '>
                                            <span className='clicli_help_text'>г. Йошкар-Ола</span>
                                            {/*<div className='float-right clicli_d_700_block'>*/}
                                            {/*    <span className='clicli_help_text'>пн-пт 9:00-16:00</span><br/>*/}
                                            {/*    <a className='float-right font-weight-bold clicli_info_number'*/}
                                            {/*       href='#'>8-800-555-55-55</a>*/}
                                            {/*</div>*/}
                                            <div className='clearfix clicli_d_700_block'></div>
                                            <div className='clicli_d_700_block clicli_about_shop_btn'
                                                 onClick={() => this.toggleAboutStoreArea()}>О магазине
                                                <img src='/images/icons/down-arrow.png' className='clicli_arrow_down'
                                                     id='clicli_arrow_down'/>
                                            </div>
                                            <div className='clicli_mt_15  clicli_d_700_none'>
                                                {this.state.page.map(items => (<div><span className='clicli_text_border-bottom mr-4'>{items.name}</span><br/></div>))}

                                            </div>
                                        </div>
                                        <div className='clicli_mt_10 d-none' id='aboutStoreArea'>
                                            {this.state.page.map(items => (<div><span className='clicli_text_border-bottom'>{items.name}</span><br/></div>))}

                                        </div>

                                        <div className='clearfix'></div>
                                    </div>
                                    <div className='position-relative clicli_d_937_flex'>
                                        {/*Корзина на маленьких экранах*/}
                                        <div className='clicli_lg_fl'>
                                            <a target="_blank" href={this.state.LinkBasket}>
                                                <div className="media clicli_product_cart_button">
                                                    <img className="clicli_product_cart_image"
                                                         src="/images/icons/supermarket.png" alt="Корзина"/>
                                                    <div
                                                        className="float-left clicli_d_600_none clicli_cart_text_700">Корзина
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        {/*Поиск на маленьких экранах*/}
                                       
                                        {/*//Иконка фильтров на маленьких экранах*/}
                                        {this.state.Breadcrumbs1 ?
                                            <div className='clicli_lg_fr clicli_937 clicli_filters_icon'
                                                 onClick={() => this.toggleFilters()}>
                                                {/*<span className='clicli_d_500_none'>Фильтры</span>*/}
                                                <img src='/images/icons/sorting-options.png' id="clicli_filter_icon"
                                                     className='clicli_standart_icon'/>
                                                <img src='/images/icons/sorting-options-close.png' id="clicli_filter_icon_close"
                                                     className='clicli_standart_icon d-none'/>
                                            </div> : ''}
                                    </div>


                                    {/*Фильтры*/}
                                    {this.state.Breadcrumbs1 ?
                                        <div className="clicli_filters_wrap clicli_937_toggle" id='clicli_filters'>
                                            <div className="clicli_filters_wrap_price">
                                                <div className="font-weight-bold">Цена</div>
                                                <div className="form-group p-0 clicli_lg_fr clicli_search">
                                                    <input value={this.state.search} onChange={this.handleSearch}
                                                           className="form-control mr-sm-2" type="search"
                                                           placeholder="Поиск по магазину"
                                                           aria-label="Поиск"/>
                                                </div>
                                                <div className="clicli_filters_wrap_price_from">
                                                    <input value={this.state.priceot}
                                                           onChange={this.handleChangePriceOT}
                                                           className="form-control" placeholder="От"/>
                                                </div>
                                                <div className="clicli_filters_wrap_price_to">
                                                    <input value={this.state.pricedo}
                                                           onChange={this.handleChangePriceDO}
                                                           className="form-control" placeholder="До"/>
                                                </div>
                                            </div>
                                            <div className="form-group clicli_filters_wrap_sort">
                                                <div className="font-weight-bold">Сортировка</div>
                                                <select value={this.state.sort} onChange={this.handleChangeSort}
                                                        className="form-control d-block">
                                                    <option value="default">По умолчанию</option>
                                                    <option value="сheaper">Дешевле</option>
                                                    <option value="expensive">Дороже</option>
                                                    <option value="alphabeticallyStart">По алфавиту (а-я)</option>
                                                    <option value="alphabeticallyEnd">По алфавиту (я-а)</option>
                                                </select>
                                            </div>
                                            {/*<div className="clicli_filters_wrap_availability">*/}
                                            {/*    <div className="font-weight-bold">Наличие</div>*/}
                                            {/*    <div className="form-check">*/}
                                            {/*        <input className="form-check-input" type="radio"*/}
                                            {/*               name="exampleRadios"*/}
                                            {/*               checked/>*/}
                                            {/*        <label className="form-check-label">*/}
                                            {/*            В наличии*/}
                                            {/*        </label>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="form-check">*/}
                                            {/*        <input className="form-check-input" type="radio"*/}
                                            {/*               name="exampleRadios"/>*/}
                                            {/*        <label className="form-check-label">*/}
                                            {/*            В наличии и под заказ*/}
                                            {/*        </label>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="form-check disabled">*/}
                                            {/*        <input className="form-check-input" type="radio"*/}
                                            {/*               name="exampleRadios"/>*/}
                                            {/*        <label className="form-check-label">*/}
                                            {/*            Все товары, включая отсутствующие в продаже*/}
                                            {/*        </label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div> : ''}
                                    {/*    О магазине на больших экранах*/}
                                    <div className='clicli_937_none'>
                                        <div className="font-weight-bold mb-2">О магазине</div>
                                        <span className='d-block clicli_help_text clicli_mb_10'>г. Йошкар-Ола</span>
                                        {this.state.page.map(items => (<div onClick={()=>this.handleOpenModal(items.id)}><span
                                            className='clicli_text_border-bottom clicli_hover_text'>{items.name}</span><br/></div>))}
                                        <p className='clicli_info_time d-inline-block mb-0 clicli_help_text'>9:00-16:00<br/>
                                            <span className='font-weight-bold clicli_info_number'>
                                            {/*<a href='#'>8-800-555-55-55</a>*/}
                                        </span>
                                        </p>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb" className='clicli_mobile_breadcrumbs'>
                                    <ol className="breadcrumb">
                                        <li onClick={() => this.handleClickCategories(-1)} className="breadcrumb-item">
                                            <img src="/images/icons/home_page.png" className="clicli_icon_breadcrumb"
                                                 alt="Каталог"/>
                                            <span>Каталог</span>
                                        </li>
                                        {this.state.Breadcrumbs1 ?
                                            <li onClick={() => this.handleClickCategories(Breadcrumbs1ID)}
                                                className="breadcrumb-item">
                                                <span>{this.state.Breadcrumbs1}</span>
                                            </li> : ''}
                                        {this.state.Breadcrumbs2 ?
                                            <li onClick={() => this.handleClickCategories(Breadcrumbs2ID)}
                                                className="breadcrumb-item" aria-current="page">
                                                <span>{this.state.Breadcrumbs2}</span>
                                            </li> : ''}
                                        {this.state.detailProductActive ?
                                            <li className="breadcrumb-item"
                                                aria-current="page">
                                                <span>{this.state.detailProductActiveName}</span>
                                            </li> : ''}
                                    </ol>
                                </nav>

                                {this.menuNode()}
                            </div>}
                    </div>
                </div>
            </div>
        )
    }

    _renderChildren = nodes => {

        const result = nodes.map(el => {
            return <li key={el.id} onClick={() => this.expandParent(el.id)}>{el.name}
                <ul>
                    <li>090</li>
                </ul>
            </li>;
        });
        return <ul>{result}</ul>;
    };

    expandParent = id => {
        console.log(id);
        this.setState({
            displayChild: id
        });
    };
}

export default Shop;