import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
} from "react-router-dom";
import Shop from './components/shop/Shop';
import Registration from './components/registration/Registration';
import Basket from './components/basket/Basket';
import Product from './components/product/DetailProduct';
import * as serviceWorker from './serviceWorker';
import Cookies from 'universal-cookie';
import MyOrder from "./components/myOrder/MyOrder";

const cookies = new Cookies();


ReactDOM.render(
    <Router>
        <Switch>
            <Route path="/shop/:IdShop" children={<ShopFunc/>} />
            <Route path="/basket/:IdShop" children={<BasketFunc/>}/>
            <Route path="/product/:IdShop/:IdProduct" children={<DetailProductFunc/>}/>
            <Route path="/order/:IdShop" children={<BasketRegFunc/>}/>
            <Route path="/my-order/:IdShop" children={<MyOrderFunc/>}/>

            <Route path="/" children={<Shop/>}/>

        </Switch>
    </Router>,
  document.getElementById('root')
);
function DetailProductFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    // @ts-ignore
    let {IdShop,IdProduct} = useParams();

    return <Product IdProduct={IdProduct} idShop={IdShop}/>;
}
function ShopFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {IdShop} = useParams();

    return <Shop idShop={IdShop}/>;
    // return <Shop idShop={IdShop}/>;
}
function BasketFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {IdShop} = useParams();
    return <Basket idShop={IdShop}/>;
}
function BasketRegFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {IdShop} = useParams();
    return <Registration idShop={IdShop}/>;
}
function MyOrderFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {IdShop} = useParams();
    return <MyOrder idShop={IdShop}/>;
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
